.alert {    
    text-align: center;
    width: 100%;   
    animation: show 3s ease-in;
}

@keyframes show{
    0%{opacity: 0;}
    10%{opacity: 1;}
    90%{opacity: 1;} 
}