@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');

:root{
  --primary-color:#0a2540;
  --button-color:#635bff;
}

body {
  margin: 0;
  background-color: #f6f9fc!important;
  font-family: 'Poppins', sans-serif!important;
  font-size: 13px!important;
}

code {
  font-family: 'Poppins', sans-serif!important;
}


.primary-color{
  color: var(--primary-color) !important;;
}

.button-color{
  border-radius: 25px!important;
  color:#fff!important;
  background-color: var(--button-color)!important;
  border: none !important;
}

a {color: var(--button-color) !important;}

section{
  padding: 100px 0px;
}

.radius{
  border-radius: 10px!important;
}
.box-shadow{
  box-shadow: 0 15px 35px #3c425714, 0 5px 15px #0000001f;
}
.card{
  border: none !important;
  animation: showCard 1s ease;
}

@keyframes showCard {
  from{opacity: 0;transform: scale(.9);}
  to{opacity: 1;transform: scale(1)}
}

input.form-control,
form select.form-control{
  border:none;
  border-radius: 0px;
  border-bottom: 1px solid #c1c1c1;
}

.bg-color{
  background-color: #0a2540!important;
}

.container{max-width: 1920px!important;}
.container>article{max-width: 1200px;}

.table{
  background-color: #0c2e4e!important;
}

tbody {
  color: white;
}

thead.thead-light{background-color: #0c2e4e!important; }

p{font-size: 15px!important;}

.card.bg-custom-card{
  background-color: #0c2e4e;
}

badge{
  font-size: 32px;
  padding: 10px;
  background-color: #1fe1a0;
  display: table;
  justify-content: center;
  margin: 20px auto 0px;
  border-radius: 5px;
  min-width: 70px;
}

#chart span.apexcharts-legend-text {
  color: white!important;
}

.tiempo{
  background-color: #06182c!important;
  position: fixed!important;
  right: 15px;
  bottom: 20px;
  z-index: 999;
}